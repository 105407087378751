<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <auth-illustration></auth-illustration>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <!-- brand logo -->
                  <app-logo></app-logo>
                <!--/ brand logo -->
                </v-card-text>

                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary my-2">
                    Adventure starts here 🚀
                  </p>
                  <p class="mb-2">
                    Make your app management easy and fun!
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="registerForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-alert
                      v-if="requestError"
                      color="error"
                      text
                      dark
                      class="my-4"
                    >
                      {{ requestError }}
                    </v-alert>

                    <v-text-field
                      v-model="name"
                      outlined
                      label="Name"
                      :error-messages="errorMessages.name"
                      :rules="[validators.required, ]"
                      placeholder="John Smith"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="email"
                      outlined
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      label="Email"
                      placeholder="Email"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :rules="[validators.required, validators.minLengthValidator]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      aria-autocomplete="off"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Confirm Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :rules="[validators.required, validators.minLengthValidator,validators.confirmedValidator(confirmPassword,password)]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-checkbox
                      hide-details
                      class="mt-0"
                    >
                      <template #label>
                        <div class="d-flex align-center flex-wrap">
                          <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                        </div>
                      </template>
                    </v-checkbox>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Sign Up
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    Already have an account?
                  </p>
                  <router-link :to="{name:'auth-login'}">
                    Sign in instead
                  </router-link>
                </v-card-text>

                <!-- divider -->
                <!-- <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text> -->

                <!-- social links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { required, emailValidator, minLengthValidator, confirmedValidator } from '@core/utils/validation'
import { ref, getCurrentInstance } from '@vue/composition-api'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import AppLogo from '@/views/components/app/AppLogo.vue'
import AuthIllustration from '@/views/components/auth/AuthIllustration.vue'

export default {
  metaInfo: {
    title: 'Register ',
  },
  components: {
    AppLogo,
    AuthIllustration,
  },
  setup() {
    // Template Refs
    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const name = ref('')
    const email = ref('')
    const password = ref('')
    const confirmPassword = ref('')

    const errorMessages = ref([])
    const requestError = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken (Register the user & login) (User will be added to the database and new generated access token will be sent for loggin in)
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      const referralCode = vm.$cookie.get('ref')
      const payload = {
        name: name.value,
        email: email.value,
        password: password.value,
        c_password: confirmPassword.value,
        referral_code: referralCode,
      }
      if (vm.$route.query.source && vm.$route.query.source.length < 11) {
        payload.source = vm.$route.query.source
      }

      axios
        .post('auth/register?v=1', payload)
        .then(response => {
          const { accessToken } = response.data

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)

          return response
        })
        .then(res => {
          vm.$cookie.set('dvbzsslg', 1, { domain: process.env.VUE_APP_DOMAIN })
          vm.$cookie.set('attkn', res.data.accessToken, { domain: process.env.VUE_APP_DOMAIN })

          axios.get('auth/user').then(response => {
            const user = response.data
            const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // ? Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // ? We will store `userAbility` in localStorage separate from userData
            // ? Hence, we are just removing it from user object
            delete user.ability

            // ? Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // Set user's data in store auth
            vm.$store.dispatch('auth/setUserData', user)

            // ? On success redirect to home
            router.push('/')
          })
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to Register!')
          console.log('error :>> ', error.response)

          // errorMessages.value = error.response.data.error
          requestError.value = error.response.data.message || error.response.data.exception || error.response.data.error
        })
    }

    return {
      isPasswordVisible,
      name,
      email,
      password,
      confirmPassword,
      errorMessages,
      handleFormSubmit,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        minLengthValidator,
        confirmedValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      registerForm,
      requestError,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
